.symbol {
    width:100px;
    height:100px;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #0010;
}

.caption{
    text-align: center;
    position: absolute;
    font-size: bold;
    top:50%;
    left:50%;
    width:100%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index:4;
}

h4{
    color:#7961aa;
    font-weight: bold;
}

h2{
    color:#3B79AD;
    font-weight: bold;
}
.footer-group h2{
    color:white;
}

.main-img img {
    width: 250px;
    height: 250px;
}
.main-img{
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(120deg,  #7961aa, #30a4dc);
    margin-bottom: 50px;
}

.main-content{
  
}
 
.main-content p{
    font-size: 20px;
}

.features{
    padding: 30px;
}

.function p{
    margin-left: 300px;
    margin-right: 300px;
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .function{
        padding-left: 50px;
        padding-right: 50px;
    }
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
  }
  @media only screen and (max-width: 800px){
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
    .function p{
        margin-left: 20px;
        margin-right: 20px;
    }
  }
  @media only screen and (max-width: 900px){
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
    .function p{
        margin-left: 20px;
        margin-right: 20px;
    }
  }

  @media only screen and (max-width: 1200px){
    .function p{
        margin-left: 150px;
        margin-right: 150px;
    }
  }

  @media only screen and (max-width: 1000px){
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
    .function p{
        margin-left: 50px;
        margin-right: 50px;
    }
  }

  @media only screen and (max-width: 400px){
    .function p{
        margin-left: 10px;
        margin-right: 10px;
    }
    .main-content p{
        margin-left: 10px;
        margin-right: 10px;
    }
  }
  
  footer{
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(120deg,  #7961aa, #30a4dc);
    color:white;
  }

  .headline{
      padding-top: 20px;
  }

  .space{
      padding-right: 5px;
  }

  .footer-group{
    margin-top: 50px;
  }

  .text{
     padding-bottom: 50px;
     padding-left: 70px;
     padding-right: 70px;
  }

 .text h4{
   color: #7961aa;
 }
