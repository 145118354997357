.ladda-background{
    background: linear-gradient(120deg, #30a4dc, #7961aa );
}

.ladda-text{
    color:white;
    text-align: initial;
}
.ladda-text h2{
    margin-top: 150px;
    color:white;
    text-align: initial;
}

.ladda-text h1{
    margin-top: 30px;
    margin-bottom: 30px;
    color:white;
    text-align: initial;
}
.ladda-img{
    margin-top: 70px;
}

.ladda-img img{
   width: 100%;
}

.app-goole-img img{
    width: 180px;
    height: 60px;
    margin-bottom: 50px;
}

.app-img img {
    width: auto;
    height: 60px;
    margin-bottom: 50px;
}

.first-img{
    float: right;
    margin: 10px;
    margin-right: 80px;
}

.second-img{
    float: right;
    margin:10px;
    margin-right: 140px;
}

@media only screen and (max-width: 600px) {
    .first-img{
        margin: 10px;
        float: none;
    }
    
    .second-img{
        margin:10px;
        float: none;
    }
}

@media only screen and (max-width: 800px) {
    .first-img{
        margin: 10px;
        float: none;
    }
    
    .second-img{
        margin:10px;
        float: none;
    }
}

.download-video{
    background-color: white;
    padding: 2em;
}
.download-video h4, h5{
    color:black;
}

.download-video p{
    color:black;
    font-size: 20px;
}