
#root .container-fluid {
    justify-content: flex-start;
    
}

.nav li{
    list-style-type: none;
    white-space: nowrap;

    font-weight: 700;
}

.nonTransparentMenu{
    background-color: white !important;
    border-bottom: 1px solid #3B79AD;
}

.transparentMenu{
    background-color: rgba(255, 255, 255, 0.9) !important;
    color:white ;
}

.dropdown:hover .dropdown-menu {
    display: block;
    background-color: rgba(255, 255, 255, 0.9) !important;
    
  }

 


  @media only screen and (max-width: 1023px) {
    .nav .dropdown-menu>li   {
     text-align: center;
    }

    
    .dropdown .dropdown-menu {
        display: block;
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: none;
        background-color: transparent;
      }

      .nav li>a{
        white-space: normal;
        color: #008CD3;
      }

      .navbar-collapse{
        height: auto;
        max-height: calc(100vh - 76px);
        overflow-x: hidden;
        
    }
    
    .navbar-collapse li{ 
        display:block;
    }

      
      
      .dropdown-toggle:after { content: none }
  }

  @media only screen and (max-width: 568px) {
    .nav .dropdown-menu>li   {
     text-align: center;
    }

    
    .dropdown .dropdown-menu {
        display: block;
        border-right: none;
        border-left: none;
        border-top: none;
        background-color: transparent;
      }
      
      .dropdown-toggle:after { content: none }

      .nav li {
        white-space: normal;
        color: #008CD3;
      }
  }
 


