@font-face {
  font-family: 'Montserrat Light';
  src: url(/static/media/Montserrat-Light.100b38fa.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(/static/media/Montserrat-Bold.9c71d42b.otf) format('opentype');
  font-weight: bold;
  font-style: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat Light !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar  a {
  color:black;
  padding: 10px;
  text-align: center;
}

.navbar-brand img {
  width:40px;
  height:40px;
}

.nav li{
  margin:10px;
 }
 
/*.font{
  font-weight:700;
}*/

.page{
 /* max-width: 720px;*/
}
.page p{
  line-height: 1.8em;
}
.footer-link, .footer-link:hover, .footer-link:visited{
  color: #fff;
}


.background{
    background: -webkit-linear-gradient(330deg, #30a4dc, #7961aa );
    background: linear-gradient(120deg, #30a4dc, #7961aa );
}
.main-image{
    padding-top:150px;
    padding-bottom: 75px;
  }

.content{
    text-align: left;
    text-align: initial;
    margin-top:50px; 
    margin-bottom:50px; 
}

h1{
    color: #30a4dc;
    font-family: Montserrat-Bold;
}

h2, h3, h4, h5{
    font-family: Montserrat-Bold;
}


@media only screen and (max-width: 600px) {
    .content{
        margin-left:30px;
        margin-right: 30px;
    }
  }

 ¨.content h1 {
     text-align: left;
     text-align: initial;
 }

.card-image {
    margin: 0;
    border-radius: 3px;
    box-shadow: 0 16px 38px -12px rgba(0,0,0,0.56), 0 4px 25px 0 rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
}

.card-image img{
    object-fit: cover;
}

.set{
    padding:30px;
    text-align: left;
}

.set a{
    color:black;
    text-decoration: none;
}

.set a:hover{
    color:#3C4858;
    text-decoration: none;
}

.set li{
    list-style-type: none;
}

.card-image:hover{
        z-index: 2;
        -webkit-transition: all 300ms ease-in;
        -webkit-tap-highlight-color: transparent;
        -webkit-transform: scale(2.5);
        -ms-transition: all 300ms ease-in;
        -ms-transform: scale(1.5);
        -moz-transition: all 300ms ease-in;
        -moz-transform: scale(1.5);
        transition: all 300ms ease-in;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
}

.ans{
    display: none;
}

.que{
    background: -webkit-linear-gradient(330deg, #30a4dc, #7961aa );
    background: linear-gradient(120deg, #30a4dc, #7961aa );
    border: 1px solid #30a4dc;
    color:white;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    cursor: pointer;
}

.que:hover{
    box-shadow: 0 16px 38px -12px rgba(0,0,0,0.56), 0 4px 25px 0 rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
}

.que i::before {
color: white;
font-size: 50px;
font-weight: bold;
width:100%;
}

.que h4{
    color: white;
}

.ladda-background{
    background: -webkit-linear-gradient(330deg, #30a4dc, #7961aa );
    background: linear-gradient(120deg, #30a4dc, #7961aa );
}

.ladda-text{
    color:white;
    text-align: left;
    text-align: initial;
}
.ladda-text h2{
    margin-top: 150px;
    color:white;
    text-align: left;
    text-align: initial;
}

.ladda-text h1{
    margin-top: 30px;
    margin-bottom: 30px;
    color:white;
    text-align: left;
    text-align: initial;
}
.ladda-img{
    margin-top: 70px;
}

.ladda-img img{
   width: 100%;
}

.app-goole-img img{
    width: 180px;
    height: 60px;
    margin-bottom: 50px;
}

.app-img img {
    width: auto;
    height: 60px;
    margin-bottom: 50px;
}

.first-img{
    float: right;
    margin: 10px;
    margin-right: 80px;
}

.second-img{
    float: right;
    margin:10px;
    margin-right: 140px;
}

@media only screen and (max-width: 600px) {
    .first-img{
        margin: 10px;
        float: none;
    }
    
    .second-img{
        margin:10px;
        float: none;
    }
}

@media only screen and (max-width: 800px) {
    .first-img{
        margin: 10px;
        float: none;
    }
    
    .second-img{
        margin:10px;
        float: none;
    }
}

.download-video{
    background-color: white;
    padding: 2em;
}
.download-video h4, h5{
    color:black;
}

.download-video p{
    color:black;
    font-size: 20px;
}
.video-one p{
    text-align: left;
}
.video-one h2{
    text-align: left;
}

#root .container-fluid {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    
}

.nav li{
    list-style-type: none;
    white-space: nowrap;

    font-weight: 700;
}

.nonTransparentMenu{
    background-color: white !important;
    border-bottom: 1px solid #3B79AD;
}

.transparentMenu{
    background-color: rgba(255, 255, 255, 0.9) !important;
    color:white ;
}

.dropdown:hover .dropdown-menu {
    display: block;
    background-color: rgba(255, 255, 255, 0.9) !important;
    
  }

 


  @media only screen and (max-width: 1023px) {
    .nav .dropdown-menu>li   {
     text-align: center;
    }

    
    .dropdown .dropdown-menu {
        display: block;
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: none;
        background-color: transparent;
      }

      .nav li>a{
        white-space: normal;
        color: #008CD3;
      }

      .navbar-collapse{
        height: auto;
        max-height: calc(100vh - 76px);
        overflow-x: hidden;
        
    }
    
    .navbar-collapse li{ 
        display:block;
    }

      
      
      .dropdown-toggle:after { content: none }
  }

  @media only screen and (max-width: 568px) {
    .nav .dropdown-menu>li   {
     text-align: center;
    }

    
    .dropdown .dropdown-menu {
        display: block;
        border-right: none;
        border-left: none;
        border-top: none;
        background-color: transparent;
      }
      
      .dropdown-toggle:after { content: none }

      .nav li {
        white-space: normal;
        color: #008CD3;
      }
  }
 



.symbol {
    width:100px;
    height:100px;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #0010;
}

.caption{
    text-align: center;
    position: absolute;
    font-size: bold;
    top:50%;
    left:50%;
    width:100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
    z-index:4;
}

h4{
    color:#7961aa;
    font-weight: bold;
}

h2{
    color:#3B79AD;
    font-weight: bold;
}
.footer-group h2{
    color:white;
}

.main-img img {
    width: 250px;
    height: 250px;
}
.main-img{
    padding-top: 100px;
    padding-bottom: 100px;
    background: -webkit-linear-gradient(330deg,  #7961aa, #30a4dc);
    background: linear-gradient(120deg,  #7961aa, #30a4dc);
    margin-bottom: 50px;
}

.main-content{
  
}
 
.main-content p{
    font-size: 20px;
}

.features{
    padding: 30px;
}

.function p{
    margin-left: 300px;
    margin-right: 300px;
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .function{
        padding-left: 50px;
        padding-right: 50px;
    }
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
  }
  @media only screen and (max-width: 800px){
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
    .function p{
        margin-left: 20px;
        margin-right: 20px;
    }
  }
  @media only screen and (max-width: 900px){
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
    .function p{
        margin-left: 20px;
        margin-right: 20px;
    }
  }

  @media only screen and (max-width: 1200px){
    .function p{
        margin-left: 150px;
        margin-right: 150px;
    }
  }

  @media only screen and (max-width: 1000px){
    .main-content p{
        margin-left: 20px;
        margin-right: 20px;
    }
    .function p{
        margin-left: 50px;
        margin-right: 50px;
    }
  }

  @media only screen and (max-width: 400px){
    .function p{
        margin-left: 10px;
        margin-right: 10px;
    }
    .main-content p{
        margin-left: 10px;
        margin-right: 10px;
    }
  }
  
  footer{
    padding-top: 100px;
    padding-bottom: 100px;
    background: -webkit-linear-gradient(330deg,  #7961aa, #30a4dc);
    background: linear-gradient(120deg,  #7961aa, #30a4dc);
    color:white;
  }

  .headline{
      padding-top: 20px;
  }

  .space{
      padding-right: 5px;
  }

  .footer-group{
    margin-top: 50px;
  }

  .text{
     padding-bottom: 50px;
     padding-left: 70px;
     padding-right: 70px;
  }

 .text h4{
   color: #7961aa;
 }

.all-con{
    padding-left: 50px;
    padding-right:50px;
}

.all-con h4{
    color:#7961aa;
}

@media only screen and (max-width: 600px) {
    .all-con{
        padding: 5px;
        padding-left: 20px;
        padding-right:20px;
    }
  }

  @media only screen and (max-width: 900px) {
    .all-con{
        padding: 5px;
        padding-left: 20px;
        padding-right:20px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .all-con{
        padding: 5px;
        padding-left: 20px;
        padding-right:20px;
    }
  }

  .all-con img{
      margin-top: 30px;
  }

  

.borderstyle{
    border:0px;
}

.datafields{
    color:black
}

.btn:hover{
    background-color: #3B79AD !important;
   
}

.align-left
{
    text-align: left;
}


.text{
    color: #212529;
    padding:0 ;
}

.main-img img {
    width: 250px;
    height: 250px;
}



.symbol{
    width:100px;
    height:100px;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #0010;
}

.caption{
    text-align: center;
    position: absolute;
    font-size: bold;
    top:50%;
    left:50%;
    width:100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
    z-index:4;
}

h4{
    color:#3B79AD;
    font-weight: bold;
}
.code-img img {
    width: 250px;
    height: 250px;
}
.background-for-share{
    padding-top: 100px;
    padding-bottom: 10px;
    background: white;
    text-align: justify;
   
  
    color:#707070;
}

#background {
    position: absolute; 
    top: 0; 
    left: 0; 
  
    /* Preserve aspet ratio */
    min-width: 100%;
    object-fit: contain;
  }

.features{
    padding: 30px;
}

.video-container {
    padding-left: 0;
    padding-right: 0;
    background: -webkit-linear-gradient(330deg, #7961aa, #30a4dc );
    background: linear-gradient(120deg, #7961aa, #30a4dc );
}

.video-container h2, .video-container h4{
  color: #fff;
}

.person-img{
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  margin-bottom: 2em;

}

.person-img:hover{
  z-index: 2;
  -webkit-transition: all 300ms ease-in;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: scale(2.5);
  -ms-transition: all 300ms ease-in;
  -ms-transform: scale(1.5);
  -moz-transition: all 300ms ease-in;
  -moz-transform: scale(1.5);
  transition: all 300ms ease-in;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
    .function{
        padding-left: 50px;
        padding-right: 50px;
    }
  }

 .share-footer{
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(51, 51, 51);
    color:white;
  }

  .headline{
      padding-top: 20px;
  }

  .space{
      padding-right: 5px;
  }

  .footer-group{
    margin-top: 50px;
  }

  .text h4{
        padding-top:30px;
  }

.margin{
    margin: 10px;
}

.down{
color: #30a4dc;
width: 50px !important;
height: 50px;
}

.second-container{
    margin-top: 50px;
    
    
}

.share-text{
    text-align: left;
}

.share-text-margin{
    margin-top:200px;
}

.no-padding p{
    font-size: 25px;
}

.no-padding h2{
    color:white;
}

.footerbar li a{
    color:white;
}

@media only screen and (max-width: 600px) {
    .share-text-margin{
        margin-top:20px;
        margin-right: 20px;
        margin-left: 20px;
        color: white;
    }
  }

  @media only screen and (max-width: 800px) {
    .share-text-margin{
        margin-top:20px;
        margin-right: 20px;
        margin-left: 20px;
        color: white;
    }
    .video{
        width:100%;
        text-align: center;
    }
  }

  @media only screen and (max-width: 1000px) {
    .share-text-margin{
        margin-top:20px;
        margin-right: 20px;
        margin-left: 20px;
        color: white;
    }
}



.footer-group li{
    list-style-type: none;
}
.post-img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    box-shadow: inset 0 0 0 1000px #0010;
}

.post-content{
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: left;
    text-align: initial;
}


