.post-img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    box-shadow: inset 0 0 0 1000px #0010;
}

.post-content{
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: initial;
}

