@font-face {
  font-family: 'Montserrat Light';
  src: url('./font/Montserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./font/montserrat/Montserrat-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat Light !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}