
.borderstyle{
    border:0px;
}

.datafields{
    color:black
}

.btn:hover{
    background-color: #3B79AD !important;
   
}

.align-left
{
    text-align: left;
}


.text{
    color: #212529;
    padding:0 ;
}

.main-img img {
    width: 250px;
    height: 250px;
}



.symbol{
    width:100px;
    height:100px;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #0010;
}

.caption{
    text-align: center;
    position: absolute;
    font-size: bold;
    top:50%;
    left:50%;
    width:100%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index:4;
}

h4{
    color:#3B79AD;
    font-weight: bold;
}
.code-img img {
    width: 250px;
    height: 250px;
}
.background-for-share{
    padding-top: 100px;
    padding-bottom: 10px;
    background: white;
    text-align: justify;
   
  
    color:#707070;
}

#background {
    position: absolute; 
    top: 0; 
    left: 0; 
  
    /* Preserve aspet ratio */
    min-width: 100%;
    object-fit: contain;
  }

.features{
    padding: 30px;
}

.video-container {
    padding-left: 0;
    padding-right: 0;
    background: linear-gradient(120deg, #7961aa, #30a4dc );
}

.video-container h2, .video-container h4{
  color: #fff;
}

.person-img{
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  margin-bottom: 2em;

}

.person-img:hover{
  z-index: 2;
  -webkit-transition: all 300ms ease-in;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: scale(2.5);
  -ms-transition: all 300ms ease-in;
  -ms-transform: scale(1.5);
  -moz-transition: all 300ms ease-in;
  -moz-transform: scale(1.5);
  transition: all 300ms ease-in;
  transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
    .function{
        padding-left: 50px;
        padding-right: 50px;
    }
  }

 .share-footer{
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(51, 51, 51);
    color:white;
  }

  .headline{
      padding-top: 20px;
  }

  .space{
      padding-right: 5px;
  }

  .footer-group{
    margin-top: 50px;
  }

  .text h4{
        padding-top:30px;
  }

.margin{
    margin: 10px;
}

.down{
color: #30a4dc;
width: 50px !important;
height: 50px;
}

.second-container{
    margin-top: 50px;
    
    
}

.share-text{
    text-align: left;
}

.share-text-margin{
    margin-top:200px;
}

.no-padding p{
    font-size: 25px;
}

.no-padding h2{
    color:white;
}

.footerbar li a{
    color:white;
}

@media only screen and (max-width: 600px) {
    .share-text-margin{
        margin-top:20px;
        margin-right: 20px;
        margin-left: 20px;
        color: white;
    }
  }

  @media only screen and (max-width: 800px) {
    .share-text-margin{
        margin-top:20px;
        margin-right: 20px;
        margin-left: 20px;
        color: white;
    }
    .video{
        width:100%;
        text-align: center;
    }
  }

  @media only screen and (max-width: 1000px) {
    .share-text-margin{
        margin-top:20px;
        margin-right: 20px;
        margin-left: 20px;
        color: white;
    }
}

