.all-con{
    padding-left: 50px;
    padding-right:50px;
}

.all-con h4{
    color:#7961aa;
}

@media only screen and (max-width: 600px) {
    .all-con{
        padding: 5px;
        padding-left: 20px;
        padding-right:20px;
    }
  }

  @media only screen and (max-width: 900px) {
    .all-con{
        padding: 5px;
        padding-left: 20px;
        padding-right:20px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .all-con{
        padding: 5px;
        padding-left: 20px;
        padding-right:20px;
    }
  }

  .all-con img{
      margin-top: 30px;
  }

  