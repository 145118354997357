
.background{
    background: linear-gradient(120deg, #30a4dc, #7961aa );
}
.main-image{
    padding-top:150px;
    padding-bottom: 75px;
  }

.content{
    text-align: initial;
    margin-top:50px; 
    margin-bottom:50px; 
}

h1{
    color: #30a4dc;
    font-family: Montserrat-Bold;
}

h2, h3, h4, h5{
    font-family: Montserrat-Bold;
}


@media only screen and (max-width: 600px) {
    .content{
        margin-left:30px;
        margin-right: 30px;
    }
  }

 ¨.content h1 {
     text-align: initial;
 }

.card-image {
    margin: 0;
    border-radius: 3px;
    box-shadow: 0 16px 38px -12px rgba(0,0,0,0.56), 0 4px 25px 0 rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
    transition: transform 1s;
}

.card-image img{
    object-fit: cover;
}

.set{
    padding:30px;
    text-align: left;
}

.set a{
    color:black;
    text-decoration: none;
}

.set a:hover{
    color:#3C4858;
    text-decoration: none;
}

.set li{
    list-style-type: none;
}

.card-image:hover{
        z-index: 2;
        -webkit-transition: all 300ms ease-in;
        -webkit-tap-highlight-color: transparent;
        -webkit-transform: scale(2.5);
        -ms-transition: all 300ms ease-in;
        -ms-transform: scale(1.5);
        -moz-transition: all 300ms ease-in;
        -moz-transform: scale(1.5);
        transition: all 300ms ease-in;
        transform: scale(1.1);
}

.ans{
    display: none;
}

.que{
    background: linear-gradient(120deg, #30a4dc, #7961aa );
    border: 1px solid #30a4dc;
    color:white;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    transition: transform 1s;
    cursor: pointer;
}

.que:hover{
    box-shadow: 0 16px 38px -12px rgba(0,0,0,0.56), 0 4px 25px 0 rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
}

.que i::before {
color: white;
font-size: 50px;
font-weight: bold;
width:100%;
}

.que h4{
    color: white;
}
